// src/App.js
import React from 'react';
import Login from "./Login";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./components/Header";
import Hero from "./components/Hero";
// import Products from "./components/Products";
import Footer from "./components/Footer";
// import Galeri from "./components/Galeri";
// import Galeri2 from "./components/Galeri2";
import Admin from './components/Admin';
import ProductsFirebase from './components/ProductsFirebase';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
// import UsersList from './components/UserCheck';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/Login"
            element={<Login />}
          />
          {/* <Route
            path="/galeri"
            element={
              <>
                <Header />
                <Galeri2 />
                <Footer />
              </>
            }
          /> */}
          <Route
            path="/admin"
            element={<Admin />}
          />
          <Route
            path="/"
            element={
              <>
                <Header />
                <Hero />
                <main className="main">
                  <ProductsFirebase />
                  {/* <Galeri /> */}
                  <Footer />
                </main>
              </>
            }
          />
        </Routes>
        <SpeedInsights />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;
