import React from 'react';
import Logo from "../components/logo14.png";
import '../components/styles/Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <div className="footer__logo-container" style={{ textAlign: 'center' }}>
                    <img
                        src={Logo}
                        alt="Beyaz Köşe Sandwich"
                        className="footer__logo"
                        style={{
                            textAlign: 'center',
                            borderRadius: '10px',
                            width: '90%',
                            height: 'auto',
                            maxWidth: '320px',
                            maxHeight: '300px'
                        }}
                    />
                </div>
                <br />
                {/* Header for Keywords */}
                <div className="footer__keywords" style={{ textAlign: 'center', marginTop: '20px' }}>

                    <h6 style={{ color: 'rgba(0, 0, 0, 0.1)' }}>izmir sandwich</h6>
                    <h6 style={{ color: 'rgba(0, 0, 0, 0.1)' }}>bostanli</h6>
                    <h6 style={{ color: 'rgba(0, 0, 0, 0.1)' }}>bostanli kumru</h6>
                    <h6 style={{ color: 'rgba(0, 0, 0, 0.1)' }}>Lezzet</h6>
                    <h6 style={{ color: 'rgba(0, 0, 0, 0.1)' }}>bostanli sandwich | bostanlı sandviç</h6>
                    <h6 style={{ color: 'rgba(0, 0, 0, 0.1)' }}>izmir kumrucu</h6>
                </div>


                <div className="footer__content grid">
                    <div className="footer__data">
                        <h6 className="footer__subtitle">Adres</h6>
                        <p className="footer__information">
                            <i className="fas fa-map-marker-alt white-marker"></i>
                            <a
                                href="https://www.google.com/maps/place/Beyaz+K%C3%B6%C5%9Fe+F%C4%B1r%C4%B1n+%26+Sandwich/@38.4580814,27.0986466,20.21z/data=!4m15!1m8!3m7!1s0x14bbd994f24787e1:0xb136d1a7129e9bae!2zQm9zdGFubMSxLCDFnmVoaXRsZXIgQmx2IE5vOjE5LCAzNTU5MCBLYXLFn8SxeWFrYS_EsHptaXI!3b1!8m2!3d38.4580153!4d27.0987964!16s%2Fg%2F11c5p17yr_!3m5!1s0x14bbd994f213e38f:0x88ee4ffcbf6f829a!8m2!3d38.4580617!4d27.098828!16s%2Fg%2F1q67ksbd9?entry=ttu"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="footer__map-link"
                            >
                                Şehitler Blv No: 19/B, <br />
                                35590 Bostanlı/Karşıyaka
                            </a>
                        </p>
                    </div>
                    <div className="footer__data">
                        <h6 className="footer__subtitle">İletişim</h6>
                        <p className="footer__information">
                            <a href="tel:+902323364550" style={{ color: 'hsl(206, 4%, 42%)', textDecoration: 'none' }}>0 (232) 3364550</a> <br />
                            <a href="mailto:birkan_t@hotmail.com" style={{ color: 'hsl(206, 4%, 42%)', textDecoration: 'none' }}>beyazkosesandwich@email.com</a>
                        </p>
                    </div>
                    <div className="footer__data">
                        <h6 className="footer__subtitle">Saatlerimiz</h6>
                        <p className="footer__information">
                            Hergün  <br />
                            08:30 - 21:00
                        </p>
                    </div>
                </div>

                <div className="footer__group">
                    {/* <ul className="footer__social">
                        <a href="https://www.instagram.com/beyazkose_sandwich/" target="_blank" className="footer__social-link">
                            <i className="bx bxl-instagram"></i>
                        </a>
                    </ul> */}

                    <span className="footer__social">&#169; <a href="mailto:ermankaval@yahoo.com" style={{ color: 'hsl(206, 4%, 42%)', textDecoration: 'none' }} >ErmanKaval</a>. All rights reserved.
                    </span>
                </div>


            </div>
        </footer>
    );
}

export default Footer;
