import React, { useEffect } from "react";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, firestore } from "../src/firebaseConfig2";
import { useNavigate } from "react-router-dom";
import "../src/components/styles/Login.css";
import logoImage from "../src/components/logo2.png";
import { getDocs, collection, query, where } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const navigate = useNavigate();
    const db = firestore;

    const handleGoogle = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const userEmail = result.user.email;

            // Search for the user in Firestore
            const usersCollection = collection(db, 'users');
            const q = query(usersCollection, where("mail", "==", userEmail));
            const userSnapshot = await getDocs(q);

            // If user exists in Firestore, navigate to admin panel
            if (!userSnapshot.empty) {
                navigate("/admin");
            } else {
                // User not found, sign out
                await auth.signOut();
                console.error('User not authorized.');
                toast.error('Kullanıcı yetkili değil!', { position: 'bottom-right', autoClose: 1000 });
            }
        } catch (error) {
            console.error(error);
            toast.error('Kullanıcı yetkili değil!', { position: 'bottom-right', autoClose: 1000 });
        }
    };


    return (
        <div className="login-container">
            <div className="logo-container">
                <a href="/">
                    <img
                        className="app-logo"
                        src={logoImage}
                        alt="App Logo"
                    />
                </a>
            </div>
            <p></p>
            <p></p>
            <h2>Admin Paneli</h2>
            <button className="google-login-btn" onClick={handleGoogle}>
                <img
                    src="https://img.icons8.com/color/48/000000/google-logo.png"
                    alt="Google Logo"
                />
                Google hesabınızla giriş yapınız
            </button>
            <ToastContainer
                position="top-right"
                style={{ top: "0", right: "0" }}
            />
        </div>
    );
};

export default Login;
