import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, orderBy } from 'firebase/firestore';
import '../components/styles/Products.css';
// import { getAnalytics } from "firebase/analytics";

const ProductsFirebase = () => {
    // const analytics = getAnalytics(app);
    const [categories, setCategories] = useState([]);
    const [uniqueCategories, setUniqueCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = getFirestore();
                const collectionRef = collection(db, 'products');

                // Fetch categories and order them by CategoryId
                const categoriesSnapshot = await getDocs(query(collectionRef, orderBy('Category')));

                const categoriesData = categoriesSnapshot.docs.map(doc => doc.data());

                // Filtreleme işlemi: Geçersiz verileri ve null'ları temizle
                const filteredCategories = categoriesData.filter(item => item && item.Category && item.Name && item.Content && item.Price);

                // Exclude "en sevilenler" and "extra" categories
                const excludedCategories = ["Soslar", "Ekstra"];
                const filteredCategoriesWithoutExcluded = filteredCategories.filter(item => !excludedCategories.includes(item.Category));

                // Sort categories by name
                const sortedCategories = filteredCategoriesWithoutExcluded.slice().sort((a, b) => a.categoryId - b.categoryId);
                // console.log(collectionRef)
                setCategories(sortedCategories);

                // Filter unique categories
                const uniqueCategoriesSet = new Set(sortedCategories.map((item) => item.Category));
                const uniqueCategoriesArray = Array.from(uniqueCategoriesSet);
                setUniqueCategories(uniqueCategoriesArray);
            } catch (error) {
                console.error('Error fetching data from Firestore:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Filter products based on selected category
        if (selectedCategory) {
            const filteredProducts = categories.filter(item => item.Category === selectedCategory);
            // Sort products by priority
            const sortedProducts = filteredProducts.slice().sort((a, b) => a.priority - b.priority);
            setFilteredProducts(sortedProducts);
        } else {
            setFilteredProducts([]);
        }
    }, [selectedCategory, categories]);

    const handleCardClick = (category) => {
        setSelectedCategory((prevCategory) => (prevCategory === category ? null : category));
    };

    return (
        <div>
            <div className="background-image-container" id="products">
                <div className="background-image" style={{ backgroundImage: `url('public/media/header.jpg')` }}></div>
            </div>
            <div className="product-card-page">
                {uniqueCategories.map((category, index) => (
                    <div
                        key={index}
                        onClick={() => handleCardClick(category)}
                        className={`product-card ${selectedCategory === category ? 'selected' : ''}`}
                    >
                        {category}
                    </div>
                ))}
            </div>
            <div className="additional-cards-container">
                {filteredProducts.map((item, index) => (
                    <div
                        key={index}
                        className={`additional-card`}
                    >
                        <div className="flex-container">
                            <p>{item.Name}</p>
                            <p className="price">₺{item.Price}</p>
                        </div>
                        <p className="content">{item.Content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductsFirebase;
