import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';
import { getFirestoreData, updateFirestoreData, deleteFirestoreData, addFirestoreData } from '../firebaseService';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import logo from "../components/logo14.png";
import '../components/styles/Admin.css';


const getArrowDirection = (sortBy, currentSortBy) => {
    if (sortBy === currentSortBy) {
        return sortBy.startsWith('-') ? '↑' : '↓';
    } else {
        return '';
    }
};


const Admin = () => {

    const [addingNewItem, setAddingNewItem] = useState(false);
    const navigate = useNavigate();
    const auth = getAuth();
    const [data, setData] = useState([]);
    const [editingItemId, setEditingItemId] = useState(null);

    const [editedItem, setEditedItem] = useState({
        id: '',
        Category: '',
        categoryId: '',
        Name: '',
        Content: '',
        Price: '',
        priority: '',
    });

    const handleAddNewClick = () => {
        setAddingNewItem(true);
        setEditedItem({
            id: '',
            Category: '',
            categoryId: '',
            Name: '',
            Content: '',
            Price: '',
            priority: '',
        });
    };

    const handleCancelAddNewClick = () => {
        setAddingNewItem(false);
        setEditedItem({
            id: '',
            Category: '',
            categoryId: '',
            Name: '',
            Content: '',
            Price: '',
            priority: '',
        });
    };



    const handleSaveNewClick = async () => {
        try {
            // Check if any of the required fields are empty
            if (
                editedItem.Category.trim() === '' ||
                editedItem.Name.trim() === '' ||
                editedItem.Content.trim() === '' ||
                editedItem.Price === '' ||
                editedItem.priority === ''
            ) {
                toast.error('Tüm alanları doldurunuz!', { position: "bottom-right", autoClose: 1500 });
                return;
            }


            // Create a new object with the generated ID and other fields
            const newItem = {
                // id: newDocumentId,
                Category: editedItem.Category,
                categoryId: parseInt(editedItem.categoryId),
                Name: editedItem.Name,
                Content: editedItem.Content,
                Price: parseInt(editedItem.Price),
                priority: parseInt(editedItem.priority),

            };

            // Add the new item to Firestore
            await addFirestoreData('products', newItem);


            // Fetch updated Firestore data
            const updatedData = await getFirestoreData('products');

            // Sort data by priority
            const sortedData = updatedData.sort((a, b) => a.priority - b.priority);

            // Update local state
            setAddingNewItem(null);
            setData(sortedData);
            // console.log(autoDocumentId)
            toast.success('Yeni kayıt eklendi!', { position: "bottom-right", autoClose: 1000 });
        } catch (error) {
            console.error('Error adding new item to Firestore:', error);
            toast.error('An error occurred while adding a new item.', { position: "bottom-right", autoClose: 1500 });
        }
    };



    const handleSaveClick = async (event) => {
        try {
            event.preventDefault(); // Prevent the default form submission behavior
            // Update Firestore data
            await updateFirestoreData('products', editedItem.id, editedItem);

            // Fetch updated Firestore data
            const updatedData = await getFirestoreData('products');

            // Sort data by priority
            const sortedData = updatedData.sort((a, b) => a.priority - b.priority);

            // Update local state
            setEditingItemId(null);
            setData(sortedData);

            toast.success('Kaydedildi!', { position: "bottom-right", autoClose: 1000 });
        } catch (error) {
            console.error('Error updating Firestore data:', error);
            toast.error('An error occurred while saving.', { position: "bottom-right", autoClose: 1000 });
        }
    };


    const [sortBy, setSortBy] = useState('Category'); // Default sorting by category
    const [arrowDirection, setArrowDirection] = useState('');
    // const [sortBypriority, setSortBypriority] = useState(false);

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };

    const handleHeaderClick = (header) => {
        setSortBy((prevSortBy) => {
            if (prevSortBy === header) {
                return `-${header}`;
            } else if (prevSortBy === `-${header}`) {
                return header;
            } else {
                return header;
            }
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const firestoreData = await getFirestoreData('products');
                let sortedData = [...firestoreData];

                if (sortBy.startsWith('-')) {
                    // Sort in descending order
                    const sortField = sortBy.substring(1);

                    if (sortField === 'Price' || sortField === 'priority' || sortField === 'categoryId') {
                        sortedData = sortedData.sort((a, b) => b[sortField] - a[sortField]);
                    } else {
                        sortedData = sortedData.sort((a, b) => b[sortField].localeCompare(a[sortField]));
                    }
                } else {
                    // Sort in ascending order
                    const sortField = sortBy;
                    if (sortField === 'Price' || sortField === 'priority' || sortField === 'categoryId') {
                        sortedData = sortedData.sort((a, b) => a[sortField] - b[sortField]);
                    } else {
                        sortedData = sortedData.sort((a, b) => a[sortField].localeCompare(b[sortField]));
                    }
                }


                setData(sortedData);
                setArrowDirection(getArrowDirection(sortBy)); // Pass sortBy to getArrowDirection
            } catch (error) {
                console.error('Error fetching Firestore data:', error);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/login');
            } else {
                fetchData();
            }
        });

        return () => unsubscribe();
    }, [auth, navigate, sortBy]);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleEditClick = (itemId) => {
        setEditingItemId(itemId);
        const editedItemData = data.find((item) => item.id === itemId);
        setEditedItem(editedItemData);
    };




    const handleCancelEdit = () => {
        // İptal edildiğinde düzenleme modundan çık
        setEditingItemId(null);

        // Orijinal verileri geri yükle
        const originalItemData = data.find((item) => item.id === editingItemId);
        setEditedItem(originalItemData);
    };

    const handleDeleteClick = async (itemId) => {
        // Ask for confirmation before deleting
        const confirmDelete = window.confirm('Are you sure you want to delete this item?');

        if (confirmDelete) {
            try {
                // Delete the item from Firestore
                // console.log('Deleting item:', itemId);
                await deleteFirestoreData('products', itemId);
                // console.log('Item deleted successfully.');

                // Fetch updated Firestore data
                // console.log('Fetching updated data...');
                const updatedData = await getFirestoreData('products');
                // console.log('Updated data:', updatedData);

                // Update local state with the updated data
                setData(updatedData);

                toast.success('Silindi!', { position: 'bottom-right', autoClose: 1000 });
            } catch (error) {
                console.error('Error deleting Firestore data:', error);
                toast.error('Silme işlemi sırasında bir hata oluştu.', { position: 'bottom-right', autoClose: 1000 });
            }
        }
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'Price' || name === 'priority' || name === 'categoryId') {
            // Check if the input value is a valid number
            if (!isNaN(Number(value))) {
                setEditedItem({
                    ...editedItem,
                    [name]: value,
                });
            } else {
                toast.error('Sadece rakam giriniz!', { position: "bottom-right", autoClose: 1000 });
            }
        } else {
            setEditedItem({
                ...editedItem,
                [name]: value,
            });
        }
    };

    return (
        <div>
            <div className="admin_header">
                <div className="admin_logo">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="sorting-dropdown">
                    <label htmlFor="sort">Sort By:   </label>
                    <select id="sort" value={sortBy} className="select-font" onChange={handleSortChange}>
                        <option value="Category">Kategori</option>
                        <option value="priority">Sıra</option>
                    </select>
                    <div className="dropdown-space"></div>
                    <button className="admin-button" onClick={handleAddNewClick}>
                        Yeni Kayıt
                    </button>
                    <button className="sign-out-button" onClick={handleSignOut}>
                        Çıkış
                    </button>
                </div>
            </div>

            {addingNewItem && (
                <div className="new-row">
                    <input
                        type="text"
                        name="Category"
                        value={editedItem.Category}
                        onChange={handleInputChange}
                        placeholder="Kategori"
                        className="input-field"
                    />
                    <input
                        type="number"
                        name="categoryId"
                        value={editedItem.categoryId}
                        onChange={handleInputChange}
                        placeholder="Kart Sıra"
                        className="input-field price-input"
                    />
                    <input
                        type="text"
                        name="Name"
                        value={editedItem.Name}
                        onChange={handleInputChange}
                        placeholder="Ürün"
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="Content"
                        value={editedItem.Content}
                        onChange={handleInputChange}
                        placeholder="İçerik"
                        className="input-field"
                    />
                    <input
                        type="number"
                        name="Price"
                        value={editedItem.Price}
                        onChange={handleInputChange}
                        placeholder="Fiyat"
                        className="input-field price-input"
                    />
                    <input
                        type="number"
                        name="priority"
                        value={editedItem.priority}
                        onChange={handleInputChange}
                        placeholder="Sıra"
                        className="input-field priority-input"
                    />
                    <div className="action-buttons">
                        <button className="save-button" onClick={handleSaveNewClick}>
                            Kaydet
                        </button>
                        <button className="cancel-button" onClick={handleCancelAddNewClick}>
                            İptal
                        </button>
                    </div>

                </div>
            )}

            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => handleHeaderClick('Category')} style={{ cursor: 'pointer' }}>
                                Kategori {getArrowDirection('Category', sortBy)}
                            </th>
                            <th onClick={() => handleHeaderClick('categoryId')} style={{ cursor: 'pointer' }}>
                                Kart Sıra {getArrowDirection('categoryId', sortBy)}
                            </th>
                            <th onClick={() => handleHeaderClick('Name')} style={{ cursor: 'pointer' }}>
                                Ürün {getArrowDirection('Name', sortBy)}
                            </th>
                            <th onClick={() => handleHeaderClick('Content')} style={{ cursor: 'pointer' }}>
                                İçerik {getArrowDirection('Content', sortBy)}
                            </th>
                            <th onClick={() => handleHeaderClick('Price')} style={{ cursor: 'pointer' }}>
                                Fiyat {getArrowDirection('Price', sortBy)}
                            </th>
                            <th onClick={() => handleHeaderClick('priority')} style={{ cursor: 'pointer' }}>
                                Sıra {getArrowDirection('priority', sortBy)}
                            </th>

                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.id}>
                                {/* <td className="admin_category">{item.Category}</td> */}
                                <td>
                                    {editingItemId === item.id ? (
                                        <input
                                            type="text"
                                            name="Category"
                                            value={editedItem.Category}
                                            onChange={handleInputChange}
                                            className="input-field category-input"
                                        />
                                    ) : (
                                        item.Category
                                    )}
                                </td>
                                <td>
                                    {editingItemId === item.id ? (
                                        <input
                                            type="integer"
                                            name="categoryId"
                                            value={editedItem.categoryId}
                                            onChange={handleInputChange}
                                            className="input-field price-input"
                                        />
                                    ) : (
                                        item.categoryId
                                    )}
                                </td>
                                <td>
                                    {editingItemId === item.id ? (
                                        <input
                                            type="text"
                                            name="Name"
                                            value={editedItem.Name}
                                            onChange={handleInputChange}
                                            className="input-field"
                                        />
                                    ) : (
                                        item.Name
                                    )}
                                </td>

                                <td>
                                    {editingItemId === item.id ? (
                                        <input
                                            type="text"
                                            name="Content"
                                            value={editedItem.Content}
                                            onChange={handleInputChange}
                                            className="input-field"
                                        />
                                    ) : (
                                        item.Content
                                    )}
                                </td>
                                <td className="price-cell">
                                    {editingItemId === item.id ? (
                                        <input
                                            type="number"
                                            name="Price"
                                            value={editedItem.Price}
                                            onChange={handleInputChange}
                                            className="input-field price-input"
                                        />
                                    ) : (
                                        `₺${item.Price}`
                                    )}
                                </td>
                                <td>
                                    {editingItemId === item.id ? (
                                        <input
                                            type="number"
                                            name="priority"
                                            value={editedItem.priority}
                                            onChange={handleInputChange}
                                            className="input-field priority-input"
                                        />
                                    ) : (
                                        item.priority
                                    )}
                                </td>
                                <td>
                                    {editingItemId === item.id ? (
                                        <>
                                            <button className="save-button" onClick={handleSaveClick}>
                                                Kaydet
                                            </button>
                                            <button className="cancel-button" onClick={handleCancelEdit}>
                                                İptal
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button className="edit-button" onClick={() => handleEditClick(item.id)}>
                                                Değiştir
                                            </button>
                                            <button className="delete-button" onClick={() => handleDeleteClick(item.id)}>
                                                Sil
                                            </button>
                                        </>
                                    )}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <ToastContainer
                position="top-right"
                style={{ top: "0", right: "0" }}
            />
        </div>
    );
};

export default Admin;