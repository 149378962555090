import React, { useEffect, useState } from 'react';
// import { Slide } from 'react-awesome-reveal';
import '../components/styles/Hero.css'; // Import your CSS file

const Hero = () => {
    const [scrolled, setScrolled] = useState(false);

    // const orderNow = () => {
    //     window.scrollTo({
    //         top: 760,
    //         behavior: 'smooth',
    //     });
    // };

    useEffect(() => {
        const handleScroll = () => {
            // Check if the user has scrolled down (you can adjust the threshold as needed)
            const isScrolled = window.scrollY > 50;
            setScrolled(isScrolled);
        };

        // Attach the event listener to the scroll event
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section className={`hero section ${scrolled ? 'show-logo' : 'hide-logo'}`} id="home">
            {/* <video className="hero__video" loop autoPlay muted playsInline>
                <source src="./media/vid3.mp4" type="video/mp4" />
            </video> */}

            <img className="hero__image" src="./media/beyazkose_logo.png" alt="Beyaz Köşe Sandwich" />
            {/* <div className="hero__overlay"></div> */}
            {/* <Slide direction="down" duration={1000}>
                <button className="hero__button" onClick={orderNow}>
                    ÜRÜNLERİMİZ
                </button>
            </Slide> */}
        </section>
    );
};

export default Hero;
