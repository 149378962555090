import { useState, useEffect } from "react";
import React from "react";
import logo from "../components/logo14.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import '../components/styles/Header.css';
// import { animateScroll as scroll } from 'react-scroll';

const Header = () => {
    const location = useLocation();
    const isGalleryPage = location.pathname === '/galeri';

    const [showLogo, setShowLogo] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const toggleMobileMenu = (e) => {
        const clickedElement = e.target;

        if (clickedElement.classList.contains('nav__link') && clickedElement.getAttribute('href') === '#products') {
            setShowMobileMenu(!showMobileMenu);

            // If mobile menu is open, scroll to the products section
            if (showMobileMenu) {
                const scrollOffset = 150;
                const productsSection = document.getElementById('products');
                if (productsSection) {
                    productsSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offsetTop: scrollOffset });
                }
            }
        } else {
            setShowMobileMenu(!showMobileMenu);

            if (showMobileMenu) {
                setShowMobileMenu(false);
            }
        }
    };



    const closeMobileMenu = () => {
        setShowMobileMenu(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setShowLogo(window.scrollY >= 50); // Show logo after scrolling 50 pixels
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="header scroll-header" id="header">
            <nav className="nav container">
                <a href="#home" className={`nav__logo ${showLogo ? 'show-logo' : 'hide-logo'}`}>
                    <img src={logo} alt="Company Logo" className="nav__logo" />
                </a>


                <div className={`nav__menu ${showMobileMenu ? 'show-menu' : ''}`} id="nav-menu">
                    <div className="nav__list">
                        <li className="nav__item">
                            <a href="/" className="nav__link active-link">Anasayfa</a>
                        </li>
                        <li className="nav__item">
                            <a href={isGalleryPage ? "/#products" : "#products"} className="nav__link" onClick={toggleMobileMenu}>
                                {isGalleryPage ? 'Ürünler' : 'Ürünler'}
                            </a>
                        </li>
                        {/* <li className="nav__item">
                            <a href="/galeri" className="nav__link">Galeri</a>
                        </li> */}
                    </div>

                    <div className="nav__close" id="nav-close" onClick={closeMobileMenu}>
                        <i className="bx bx-x"></i>
                    </div>
                </div>

                <div className="nav__contact-container">

                    <ul className="footer__social">
                        <a href="https://www.instagram.com/beyazkose_sandwich/" target="_blank" className="footer__social-link">
                            <i className="bx bxl-instagram"></i>
                        </a>
                    </ul>

                    <a href="tel:+902323364550" className="nav__contact" >
                        <FontAwesomeIcon icon={faPhone} />
                        0 (232) 3364550
                    </a>
                </div>

                <div className="nav__toggle" id="nav-toggle" onClick={toggleMobileMenu}>
                    <i className="bx bx-grid-alt"></i>
                </div>
            </nav>
        </header>
    );
};

export default Header;
